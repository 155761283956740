<template>
  <div class="back-class">
    <div class="option-class">
      <el-select
        v-model="typevalue"
        placeholder="请选择"
        size="mini"
        @change="setselect()"
      >
        <el-option
          v-for="item in typelist"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button
        type="primary"
        size="mini"
        class="btn-class"
        v-if="userdata.identity == 0"
        @click="gl()"
        >管理</el-button
      >
    </div>
    <div class="home-body">
      <div class="card-class" v-for="(item, key) in stdata" :key="'' + key">
        <div class="card-text-public title" @click="gotosee(item)">
          {{ item.title }}
        </div>
        <div class="card-text-public context" @click="gotosee(item)">
          {{ item.upcontent }}
        </div>
        <div class="card-text-public other">
          {{ "浏览量：" + item.browse + " | 点赞量：" + item.number }}
          <el-button
            type="danger"
            size="mini"
            class="btn-class"
            v-if="userdata.identity == 0"
            @click="delre(item)"
            >删除</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typevalue: "0",
      typelist: [
        {
          value: "0",
          label: "未处理",
        },
        {
          value: "1",
          label: "已处理并公开",
        },
        {
          value: "-1",
          label: "不公开",
        },
      ],
      stdata: [],
      userdata: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setselect();
      this.$axios
        .get("/appealapi/getu.php?o=" + sessionStorage.getItem("openid"))
        .then((res) => {
          this.$set(this, "userdata", res.data);
        });
    },
    gotosee(item) {
      this.$router.push({ path: "/reply", query: { id: item.id } });
    },
    setselect() {
      this.$axios
        .get("/appealapi/getall2type.php?t=" + this.typevalue)
        .then((res) => {
          this.stdata = res.data;
        });
    },
    delre(item) {
      this.$confirm("此操作将永久删除该信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          this.$axios.get("/appealapi/delre.php?c=" + item.id);
          this.$message.success("删除成功！");
          this.setselect();
        })
        .catch(() => {});
    },
    gl() {
      this.$router.push("/types");
    },
  },
};
</script>

<style lang="scss" scoped>
.back-class {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .option-class {
    width: 100%;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-body {
    height: calc(100% - 80px);
    width: 96%;
    margin: 10px 0px;
    .card-class {
      width: 100%;
      border-bottom: 1px solid#EEEEEE;
    }
    .title {
      font-size: 17px;
    }
    .context {
      font-size: 13px;
      color: #a9a9a9;
    }
    .other {
      font-size: 13px;
      color: #d6d6d6;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .btn-class {
    margin: 0 20px;
  }
}
</style>